var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fetch-data',{staticClass:"relative",attrs:{"url":"offers","params":_vm.query,"force-update":_vm.updatesCounter},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var results = ref.results;
var isLoading = ref.isLoading;
var pagination = ref.pagination;
return [(results && results.length)?_c('table-display',{attrs:{"rows":results,"columns":_vm.columns,"row-size":_vm.genericTableRowSize.SMALL,"table-style":_vm.TableStyle.Flat,"sort":_vm.sortConfig},on:{"sort":_vm.sort,"record-click":function($event){return _vm.$router.push({
        name: 'OfferDetails',
        params: { offerId: $event.id },
      })}},scopedSlots:_vm._u([{key:"statusCell",fn:function(ref){
      var value = ref.value;
return [_c('status-toggler',{attrs:{"colors":_vm.OFFER_STATUS_COLORS,"options":[],"current-status":value,"status-translation":"offer-status","disabled":""}})]}},{key:"actionsCell",fn:function(ref){
      var row = ref.row;
return [(_vm.showDeleteOfferConfirmModal)?_c('portal',{attrs:{"to":"modals"}},[_c('confirm-modal',{attrs:{"show":_vm.showDeleteOfferConfirmModal,"title":_vm.$t('offer.archive-confirm')},on:{"confirm":function($event){return _vm.handleArchiveOffer(row)},"close":function($event){_vm.showDeleteOfferConfirmModal = false}}})],1):_vm._e(),_c('ellipsis-menu',{staticClass:"text-gray-darker",attrs:{"items":_vm.getActions(row),"absolute":false,"append-to-body":""},nativeOn:{"click":function($event){$event.stopPropagation();}}})]}}],null,true)}):_vm._e(),_c('table-pagination',{staticClass:"px-4 md:px-0",attrs:{"path":"offers","pagination":pagination,"is-loading":isLoading,"query":_vm.query}}),(isLoading)?_c('table-loader',{staticClass:"mt-5"}):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }