import Btn from '@/components/Button/Button.vue';
import { Icon } from '@/components/Icon';
import ReportFilters from '@/views/Brand/Applications/Components/Sidebar/ReportFilters.vue';
import { defineComponent, ref } from '@vue/composition-api';
import { ReportMenu } from '@/views/Brand/Applications/Components/ReportMenu';
import { mdiPlus } from '@mdi/js';
import { ReportMenuItem } from '@/views/Brand/Applications/Components/Sidebar/sidebar.constants';
export default defineComponent({
    name: 'ReportsSidebar',
    props: {
        hideFilters: {
            type: Boolean,
            default: false,
        },
        actionRequiredMessagesUpdatesCounter: Number,
    },
    components: {
        ReportMenu,
        Btn,
        Icon,
        ReportFilters,
    },
    setup() {
        const activeMenuItem = ref(ReportMenuItem.ALL);
        return { mdiPlus, activeMenuItem };
    },
});
