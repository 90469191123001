import FetchData from '@/components/DataProvider/FetchData.vue';
import { GenericTable, TableStyle } from '@/components/Table/__new__';
import { computed, defineComponent, watch, ref } from '@vue/composition-api';
import { deleteOffer } from '@/api/requests/offers';
import { date } from '@/util/formatters';
import StatusToggler from '@/components/StatusToggler/StatusToggler.vue';
import { OFFER_STATUS_COLORS } from '@/constants/offer';
import { genericTableRowSize } from '@/components/Table/__new__/_components/TableCellGeneric';
import { useAuthUser, useRouteQuery, useSort } from '@/composables';
import EllipsisMenu from '@/components/EllipsisMenu/EllipsisMenu.vue';
import { useUpdatesCounter } from '@/composables/use-updates-counter';
import { TablePagination } from '@/views/_components/Table';
import TableLoader from '@/views/_components/Table/TableLoader.vue';
import { SystemRole } from '@/api/requests/users';
import ConfirmModal from '@/components/Modal/__new__/ConfirmModal.vue';
export default defineComponent({
    components: {
        ConfirmModal,
        TableLoader,
        TablePagination,
        EllipsisMenu,
        StatusToggler,
        FetchData,
        TableDisplay: GenericTable(),
    },
    setup(_, ctx) {
        const params = ctx.root.$route.params;
        const { updatesCounter, update } = useUpdatesCounter();
        const { routeQuery, updateQueryParameter } = useRouteQuery(ctx);
        const { sortConfig, sortBy, sort, setSortBy } = useSort();
        const showDeleteOfferConfirmModal = ref(false);
        const query = computed(() => {
            return {
                ...routeQuery.value,
                brandId: params.id,
            };
        });
        const { hasRole } = useAuthUser();
        const handleArchiveOffer = async (offer) => {
            await deleteOffer(offer.id);
            update();
            showDeleteOfferConfirmModal.value = false;
        };
        const getActions = (offer) => {
            if (hasRole(SystemRole.SUPER_ADMIN) || hasRole(SystemRole.CONTRACTOR)) {
                return {
                    edit: {
                        name: ctx.root.$i18n.t('ellipsis-menu.edit'),
                        handler() {
                            ctx.root.$router.push({
                                name: 'EditOffer',
                                params: { offerId: offer.id },
                            });
                        },
                    },
                    archive: {
                        name: ctx.root.$i18n.t('ellipsis-menu.archive'),
                        async handler() {
                            showDeleteOfferConfirmModal.value = true;
                        },
                    },
                };
            }
            return [];
        };
        const columns = computed(() => {
            return [
                {
                    key: 'internalNumber',
                    label: '#',
                    sortable: true,
                },
                {
                    key: 'report',
                    label: ctx.root.$t('brand-offers.report').toString(),
                    formatter: (value) => value?.numberByBrand ? `#${value.numberByBrand}` : '-',
                    hideInMobile: true,
                    sortable: true,
                },
                {
                    key: 'title',
                    label: ctx.root.$t('brand-offers.title').toString(),
                    gridColumn: '0.8fr',
                    sortable: true,
                },
                {
                    key: 'createdAt',
                    label: ctx.root.$t('brand-offers.created').toString(),
                    formatter: date,
                    hideInMobile: true,
                    sortable: true,
                },
                {
                    key: 'status',
                    label: ctx.root.$t('brand-offers.status').toString(),
                    hideDefaultContent: true,
                    hideInMobile: true,
                    gridColumn: '0.1fr',
                },
                {
                    key: 'actions',
                    cellClass: 'justify-end',
                    hideDefaultContent: true,
                },
            ];
        });
        watch(routeQuery, (value) => {
            if (value.sortBy && sortBy !== value.sortBy)
                setSortBy(value.sortBy);
        });
        watch(sortBy, (value) => {
            updateQueryParameter({
                route: ctx.root.$route,
                parameter: 'sortBy',
                value: { value },
            });
            update();
        });
        return {
            showDeleteOfferConfirmModal,
            handleArchiveOffer,
            columns,
            query,
            TableStyle,
            genericTableRowSize,
            OFFER_STATUS_COLORS,
            getActions,
            updatesCounter,
            routeQuery,
            sortConfig,
            sort,
        };
    },
});
