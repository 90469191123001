<template>
  <div class="price-comments">
    <div class="px-4 pb-4 pt-8">
      <p class="flex justify-between">
        <span class="text underline">
          {{ $t('object-details-view.exposure') }}:
        </span>
        <span>{{ object.monthlyPrice }} {{ $t('common.currency') }}</span>
      </p>
    </div>
    <div v-if="offerObject" class="px-2 border-t border-gray-light">
      <object-accept :object="object" :offer-id="currentOffer.id" />
    </div>
  </div>
</template>

<script>
import ObjectAccept from '@/views/Brand/Objects/_components/Offer/ObjectAccept';
import { mapState } from 'vuex';

export default {
  components: { ObjectAccept },
  props: {
    offerObject: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('object', {
      object: 'details',
      objectType: 'objectType',
    }),
    ...mapState('offer', ['currentOffer']),
  },
};
</script>
